<template>
    <div class="recordPage">
        <div class="line" v-if="list.length>0"></div>
        <div v-if="list.length>0">
            <template v-for="(item,index) in list">
                <div :key='index'>                
                    <div>
                        <ul>
                            <li>
                                <span class="label">申请时间：</span>
                                <span class="text">{{item.applyTime}}</span>
                            </li>
                            <li>
                                <span class="label">退费人数：</span>
                                <span class="text">{{item.refundCount}}人</span>
                            </li>
                            <li>
                                <span class="label">退费金额：</span>
                                <span class="text">￥{{item.refundAmount}}</span>
                            </li>
                            <li>
                                <span class="label">退费状态：</span>
                                <span v-if='item.rejectStatus==2' class="text success">成功</span>
                                <span v-else-if="item.rejectStatus==1" class="text fail">驳回</span>
                                <span v-else class="text audit">审核中</span>
                            </li>

                        </ul>
                        <Cell title="退费原因" is-link @click="handleShowModal(item.applyReason,'退费原因')"/>
                        <Cell title="驳回原因" v-if="item.rejectStatus==1" is-link @click="handleShowModal(item.rejectReason,'驳回原因')"/>
                        <div class="line"></div>
                    </div>
                </div>
            </template>
        </div>
        <div v-else class="noMeet">
            <Icon name="notes-o" color="#999" size="100" />
            <span>暂时没有退费记录</span>
        </div>
        <action-sheet v-model="showModal" :title="modalTitle">
            <div class="content">
                {{content}}
            </div>
        </action-sheet>
    </div>
</template>

<script>
import {Cell,ActionSheet,Icon } from "vant";
import {getRefundRecords} from "@/api/my.js"

    export default {
        name:'refundRecords',
        components:{
            Cell,
            ActionSheet ,
            Icon
        },
        data(){
            return{
                list:[],
                showModal:false,
                content:'',
                modalTitle:'',
                orderId:'',
                
            }
        },
        created(){
            let {orderId}=this.$route.query
            this.orderId=orderId;
            this.getRecords(orderId);
        },
        methods:{
            getRecords(orderId){
                let clientId=localStorage.getItem("clientId");
                getRefundRecords({clientId,orderId,pageIndex:1,pageSize:100}).then(res=>{
                    if(res.status=='S'){
                        this.list=[...res.data.list]
                    }
                })

            },
            handleShowModal(reason,title){
                this.modalTitle=title;
                this.content=reason;
                this.showModal=true
            }
        }
    }
</script>

<style lang="less" scoped>
::v-deep.van-cell{
    padding:0.2rem 0.3rem;
    
}
::v-deep.van-cell::after{
    left:0.3rem;
    right:0.3rem;
}
::v-deep.van-action-sheet__header{
    border-bottom: 1px solid #ebedf0;;
}
.recordPage{
    .line{
        height: 0.1rem;
        background: #f5f5f5;
    }
    ul{
        margin:0 0.3rem;
        padding:0.1rem 0 0.15rem;
        border-bottom: 1px solid #ebedf0;
        li{
            margin-top: 0.1rem;
            font-size: 14px;
            color: #999;            
            .text{
                color: #333;
            }
            .success{
                color: #0fa84f;
            }
            .fail{
                color: #f62f2f;
            }
            .audit{
                color: #014fa2;
            }
        }
    }
    .content{
        padding:0.2rem 0.3rem 0.4rem;
        line-height: 25px;
        color: #666;
        font-size: 14px;
    }
}
.noMeet {
    padding-top: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    span {
      margin-top: 0.3rem;
      color: #999;
      font-size: 22px;
      font-weight: bold;
    }
  }
</style>